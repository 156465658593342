/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

*:not(mat-icon) {
  font-family: myriad-pro, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  background-color: #E9ECEC;
}

h1 {
  text-align: center;
  font-weight: 700 !important;
  /* color: #0166A4; */
  color: #5D6A72
}

h2, h3, h4, p {
  color: #5D6A72;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 20px auto;
  text-align: justify;
}

input#website {
  display: none;
}

.header-container {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-image: url('assets/images/girl_only_1-01.png');
  background-size: cover;
  padding-top: 20px;
}

.header-container img {
  margin: 50px auto;
  width: calc(80% - 50vw);
}

.footer-container {
  display: block;
  height: 100px;
  color: #0166A4;
  padding: 10px;
}

.window-container {
  padding-top: 50px;
  background-color: #E9ECEC;
}

.window-container-tou {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;

  padding: 50px;
  background-color: #E9ECEC;
}

.form-container {
  text-align: center;
  width: 300px;
  margin: 30px auto;
}

.form-container-stepper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  /* margin: 20px auto;
  margin-top: 30px */
}

.button-container {
  width: 200px;
  margin-top: 15px !important;

}

.form-field {
  margin: 3px;
  width: 300px;
}

.form-field-ta {
  margin: 3px;
  width: 100%;
}

.form-field-container-inline {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.hints-card-container {
  padding-top: 30px;
  padding-bottom: 200px;
  text-align: center;
}

.hints-card {
  display: inline-block;
  width: 140px;
  text-align: center;
}

.general-info {
  background-color: skyblue;
  border-radius: 5px;
  padding: 15px;
}

.specific-info {
  color: slategray;
  font-weight: bolder;
  padding: 10px;
}

/* Table of Requests */
.requests-table {
  margin: 15px auto;
  width: 400px;
  text-align: center;
}

/* Uploader Page */
.mydropzone {
  border: 1px dashed #13B2EB;
  background: #CFF0FB;
  border-radius: 8px;
  padding: 24px;
  margin: 5px auto;
  width: 365px;
  text-align: center;
}

.myuploadfilesinput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}

.mycard {
  margin: 0;
  padding: 0;
}

.mycardzone {
  display: flex;
  max-width: 1000px;
  flex-wrap: wrap;
  text-align: center;
  padding: 2em 20.5%;
}

.mycardzonethumbnail {
  flex: 0 0 0 25%;
  margin: 2px;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.mycard-name-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}

.document-thumbnail:before {
  content: url('assets/images/icon-document.png');
  width: 100px;
  height: 75px;
}

.mylistzone {
  width: 350px;
  text-align: justify;
}

.tablerow {
  border-bottom: 1px solid white;
}

/* End Uploader Page */

/* Progress Spinner */
/* Absolute Center Spinner */
.loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Spinner Transparent Overlay */
.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner-overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.6;
}

/* Language changer */
.language-changer {
  width: 30px;
  z-index: 3;
  display: inline;
}

.language-changer a {
  color: #93A2B0;
  text-decoration: none;
}

.language-changer a:hover {
  color: blue;
}


@media only screen and  (max-width: 997px) {
  .header-container img {
    margin: 50px auto;
    width: 400px;
  }
}

@media only screen and  (max-width: 420px) {
  .header-container img {
    margin: 50px auto;
    width: 330px;
  }
}

@media only screen and  (max-width: 280px) {
  .header-container img {
    margin: 50px auto;
    width: 250px;
  }
  .form-field {
    width: auto;
  }
  .hints-card {
    width: 150px;
  }
  .form-container {
    width: 200px;
  }
}
